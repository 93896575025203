(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/sport-event-common/assets/javascripts/sport-event-status.js') >= 0) return;  svs.modules.push('/components/sport/sport-event-common/assets/javascripts/sport-event-status.js');
"use strict";


const SportEventStatus = {
  Undefined: 'Undefined',
  NotStarted: 'NotStarted',
  AboutToStart: 'AboutToStart',
  Live: 'Live',
  FirstPeriod: 'FirstPeriod',
  SecondPeriod: 'SecondPeriod',
  ThirdPeriod: 'ThirdPeriod',
  FirstHalf: 'FirstHalf',
  SecondHalf: 'SecondHalf',
  FirstQuarter: 'FirstQuarter',
  SecondQuarter: 'SecondQuarter',
  ThirdQuarter: 'ThirdQuarter',
  FourthQuarter: 'FourthQuarter',
  FirstInning: 'FirstInning',
  SecondInning: 'SecondInning',
  ThirdInning: 'ThirdInning',
  FourthInning: 'FourthInning',
  FifthInning: 'FifthInning',
  SixthInning: 'SixthInning',
  SeventhInning: 'SeventhInning',
  EighthInning: 'EighthInning',
  NinthInning: 'NinthInning',
  Overtime: 'Overtime',
  FirstExtraPeriod: 'FirstExtraPeriod',
  SecondExtraPeriod: 'SecondExtraPeriod',
  ThirdExtraPeriod: 'ThirdExtraPeriod',
  AwaitingPenalties: 'AwaitingPenalties',
  Penalties: 'Penalties',
  Pause: 'Pause',
  AwaitingExtratime: 'AwaitingExtratime',
  Interrupted: 'Interrupted',
  Abandoned: 'Abandoned',
  Postponed: 'Postponed',
  Delayed: 'Delayed',
  Cancelled: 'Cancelled',
  Ended: 'Ended',
  Closed: 'Closed',
  Halftime: 'Halftime',
  Fulltime: 'Fulltime',
  Extratime: 'Extratime',
  EndedAfterExtratime: 'EndedAfterExtratime',
  EndedAfterPenalties: 'EndedAfterPenalties',
  FallbackFourthPeriod: 'FallbackFourthPeriod',
  FallbackFifthPeriod: 'FallbackFifthPeriod',
  FallbackFirstSet: 'FallbackFirstSet',
  FallbackSecondSet: 'FallbackSecondSet',
  FallbackThirdSet: 'FallbackThirdSet',
  FallbackFourthSet: 'FallbackFourthSet',
  FallbackFifthSet: 'FallbackFifthSet',
  FallbackExtraTimeHalftime: 'FallbackExtraTimeHalftime',
  FallbackSuspended: 'FallbackSuspended',
  FallbackWalkover: 'FallbackWalkover',
  FallbackParticipantRetired: 'FallbackParticipantRetired',
  FallbackInFirstBreak: 'FallbackInFirstBreak',
  FallbackInSecondBreak: 'FallbackInSecondBreak',
  FallbackInThirdBreak: 'FallbackInThirdBreak',
  FallbackGoldenSet: 'FallbackGoldenSet',
  FallbackSixthSet: 'FallbackSixthSet',
  FallbackSeventhSet: 'FallbackSeventhSet',
  FallbackFrame1: 'FallbackFrame1',
  FallbackFrame2: 'FallbackFrame2',
  FallbackFrame3: 'FallbackFrame3',
  FallbackFrame4: 'FallbackFrame4',
  FallbackFrame5: 'FallbackFrame5',
  FallbackFrame6: 'FallbackFrame6',
  FallbackFrame7: 'FallbackFrame7',
  FallbackFrame8: 'FallbackFrame8',
  FallbackFrame9: 'FallbackFrame9',
  FallbackFrame10: 'FallbackFrame10',
  FallbackFrame11: 'FallbackFrame11',
  FallbackFrame12: 'FallbackFrame12',
  FallbackFrame13: 'FallbackFrame13',
  FallbackFrame14: 'FallbackFrame14',
  FallbackFrame15: 'FallbackFrame15',
  FallbackFrame16: 'FallbackFrame16',
  FallbackFrame17: 'FallbackFrame17',
  FallbackFrame18: 'FallbackFrame18',
  FallbackFrame19: 'FallbackFrame19',
  FallbackFrame20: 'FallbackFrame20',
  FallbackFrame21: 'FallbackFrame21',
  FallbackFrame22: 'FallbackFrame22',
  FallbackFrame23: 'FallbackFrame23',
  FallbackFrame24: 'FallbackFrame24',
  FallbackFrame25: 'FallbackFrame25',
  FallbackFrame26: 'FallbackFrame26',
  FallbackFrame27: 'FallbackFrame27',
  FallbackFrame28: 'FallbackFrame28',
  FallbackFrame29: 'FallbackFrame29',
  FallbackFrame30: 'FallbackFrame30',
  FallbackFrame31: 'FallbackFrame31',
  FallbackFrame32: 'FallbackFrame32',
  FallbackFrame33: 'FallbackFrame33',
  FallbackFrame34: 'FallbackFrame34',
  FallbackFrame35: 'FallbackFrame35',
  FallbackFrame36: 'FallbackFrame36',
  FallbackFrame37: 'FallbackFrame37',
  FallbackAwaitingSuddenDeath: 'FallbackAwaitingSuddenDeath',
  FallbackInSuddenDeath: 'FallbackInSuddenDeath',
  FallbackEndedAfterSuddenDeath: 'FallbackEndedAfterSuddenDeath',
  FallbackFirstInningsHomeTeam: 'FallbackFirstInningsHomeTeam',
  FallbackFirstInningsAwayTeam: 'FallbackFirstInningsAwayTeam',
  FallbackSecondInningsHomeTeam: 'FallbackSecondInningsHomeTeam',
  FallbackSecondInningsAwayTeam: 'FallbackSecondInningsAwayTeam',
  FallbackAwaitingSuperOver: 'FallbackAwaitingSuperOver',
  FallbackInSuperOverHomeTeam: 'FallbackInSuperOverHomeTeam',
  FallbackInSuperOverAwayTeam: 'FallbackInSuperOverAwayTeam',
  FallbackEndedAfterSuperOver: 'FallbackEndedAfterSuperOver',
  FallbackStumps: 'FallbackStumps',
  FallbackDefaulted: 'FallbackDefaulted',
  FallbackWalkoverHomeTeamWon: 'FallbackWalkoverHomeTeamWon',
  FallbackWalkoverAwayTeamWon: 'FallbackWalkoverAwayTeamWon',
  FallbackParticipantRetiredHomeTeamWon: 'FallbackParticipantRetiredHomeTeamWon',
  FallbackParticipantRetiredAwayTeamWon: 'FallbackParticipantRetiredAwayTeamWon',
  FallbackDefaultedHomeTeamWon: 'FallbackDefaultedHomeTeamWon',
  FallbackDefaultedAwayTeamWon: 'FallbackDefaultedAwayTeamWon',
  FallbackLunchBreak: 'FallbackLunchBreak',
  FallbackTeaBreak: 'FallbackTeaBreak'
};
setGlobal('svs.components.sport.sportEventCommon.SportEventStatus', SportEventStatus);

 })(window);