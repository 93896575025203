(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/sport-event-common/assets/javascripts/result-types.js') >= 0) return;  svs.modules.push('/components/sport/sport-event-common/assets/javascripts/result-types.js');
"use strict";


const ResultTypes = {
  Undefined: 'Undefined',
  Current: 'Current',
  Halftime: 'Halftime',
  Fulltime: 'Fulltime',
  Overtime: 'Overtime',
  Penalties: 'Penalties',
  Period1: 'Period1',
  Period2: 'Period2',
  Period3: 'Period3',
  Period4: 'Period4',
  Period5: 'Period5',
  Period6: 'Period6',
  Period7: 'Period7',
  Period8: 'Period8',
  Period9: 'Period9',
  FallbackBetradarOddsinfoCurrent: 'FallbackBetradarOddsinfoCurrent',
  FallbackGrsCurrent: 'FallbackGrsCurrent',
  FallbackTennisCurrent: 'FallbackTennisCurrent',
  FallbackGameCurrent: 'FallbackGameCurrent',
  FallbackBetradar5: 'FallbackBetradar5',
  FallbackBetradarSets: 'FallbackBetradarSets',
  FallbackBetradarGoldenSet: 'FallbackBetradarGoldenSet',
  FallbackBetradarE1: 'FallbackBetradarE1',
  FallbackBetradarE2: 'FallbackBetradarE2',
  FallbackBetradarWinner: 'FallbackBetradarWinner',
  FallbackBetradarEI: 'FallbackBetradarEI',
  FallbackOddsinfoCurrent: 'FallbackOddsinfoCurrent',
  FallbackEI: 'FallbackEI',
  FallbackDeprecated: 'FallbackDeprecated',
  FallbackExtraInnings3: 'FallbackExtraInnings3',
  FallbackExtraInnings: 'FallbackExtraInnings'
};
setGlobal('svs.components.sport.sportEventCommon.ResultTypes', ResultTypes);

 })(window);