(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/sport-event-common/assets/javascripts/helpers.js') >= 0) return;  svs.modules.push('/components/sport/sport-event-common/assets/javascripts/helpers.js');
"use strict";


const {
  ResultTypes
} = svs.components.sport.sportEventCommon;
const currentResultTypes = [ResultTypes.Current, ResultTypes.FallbackBetradarOddsinfoCurrent, ResultTypes.FallbackGrsCurrent, ResultTypes.FallbackTennisCurrent, ResultTypes.FallbackGameCurrent, ResultTypes.FallbackOddsinfoCurrent];
const getCurrentResult = results => {
  if (!results || !Array.isArray(results)) {
    return undefined;
  }
  for (let i = 0; i < results.length; i++) {
    for (let j = 0; j < currentResultTypes.length; j++) {
      if (results[i].type === currentResultTypes[j]) {
        return results[i];
      }
    }
  }
  return undefined;
};
setGlobal('svs.components.sport.sportEventCommon.helpers', {
  getCurrentResult
});

 })(window);